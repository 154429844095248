import React, { useState, useEffect, useContext } from "react";
import ArtCard from "../ArtCard";
import "./Art.css";
import { ImageConext } from "../imageContext";

import { SRLWrapper } from "simple-react-lightbox";
const options = {
  settings: {
    overlayColor: "rgba(39, 39, 39, 1)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
    showFullscreenButton: false,
  },
  buttons: {
    showDownloadButton: false,
    backgroundColor: "#2B50AA",
    iconColor: "rgba(126, 172, 139, 0.8)",
  },
  caption: {
    captionAlignment: "start",
    captionColor: "#FFFFFF",
    captionContainerPadding: "0",
    captionFontFamily: "inherit",
    captionFontSize: "inherit",
    captionFontStyle: "inherit",
    captionFontWeight: "inherit",
    captionTextTransform: "inherit",
    showCaption: true,
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment: "center",
    thumbnailsContainerBackgroundColor: "transparent",
    thumbnailsContainerPadding: "0",
    thumbnailsGap: "1px",
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: "left",
    thumbnailsSize: ["100px", "80px"],
  },
};

function AndreTing() {
  const [andreTing] = useContext(ImageConext);

  const [mobile, setMobile] = useState({
    mobileView: false,
  });

  const { mobileView } = mobile;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobile((prevState) => ({ ...prevState, mobileView: true }))
        : setMobile((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <SRLWrapper options={options}>
        <div className="art__imageContainer">
          {andreTing.map(({ id, image }) => (
            <ArtCard
              key={id}
              folder={"andreTing"}
              id={id}
              title={image.title}
              imageUrl={image.imageUrl}
              alt={image.alt}
              dimension={image.dimension}
              solgt={image.solgt}
              description={image.description}
            />
          ))}
        </div>
      </SRLWrapper>
    );
  };

  const displayMobile = () => {
    return (
      <div className="art__imageContainerMobile">
        {andreTing.map(({ id, image }) => (
          <ArtCard
            key={id}
            folder={"andreTing"}
            id={id}
            title={image.title}
            imageUrl={image.imageUrl}
            alt={image.alt}
            dimension={image.dimension}
            solgt={image.solgt}
            description={image.description}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="art">{mobileView ? displayMobile() : displayDesktop()}</div>
  );
}

export default AndreTing;
