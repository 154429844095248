import React from "react";
import { Button } from "@material-ui/core";
import { db } from "./firebase.js";
import DeleteIcon from "@material-ui/icons/Delete";

function DeleteButton({ id, folder }) {
  // needs to be global state instead.
  //   const [user, setUser] = useContext(GlobalState);

  //   useEffect(() => {
  //     const loggedIn = auth.onAuthStateChanged((user) => {
  //       if (user) {
  //         console.log("username", user);
  //         setUser(user);
  //       } else {
  //         console.log("NOUSER");
  //       }
  //     });
  //     return () => {
  //       loggedIn();
  //       console.log("LOGGED", loggedIn());
  //     };
  //   }, []);
  //   console.log("USERLOG", user);

  const deleteSaftey = () => {
    var proceed = window.confirm("are you sure?");
    if (proceed) {
      db.collection(folder)
        .doc(id)
        .delete()
        .then(function () {
          console.log("deleted");
        })
        .catch(function (error) {
          console.log("error");
        });
    } else {
      console.log("not deletede");
    }
  };

  return (
    <div>
      <div>
        <Button onClick={(event) => deleteSaftey()}>
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
}

export default DeleteButton;
