import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./Header";
import HomePage from "./HomePage";
import About from "./About";
import SortImage from "./gallery/SortImage";

import ImageUpload from "./ImageUpload";
import Login from "./Login";
import "./App.css";
import AndreTing from "./gallery/AndreTing";
import IngenTing from "./gallery/IngenTing";
import SimpleReactLightbox from "simple-react-lightbox";

import { ImageProvider } from "./imageContext";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-236987138-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID, {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: 123,
  },
});

// Til min hjemmeside så tænker jeg at den ene rubrik skal hedde: “Ingen Ting” og den andre: “Andre Ting”
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ImageProvider>
      <SimpleReactLightbox>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/About" component={About} />
            <Route path="/Login" component={Login} />
            <Route path="/ImageUpload" component={ImageUpload} />
            <Route path="/andreting" component={AndreTing} />
            <Route path="/IngenTing" component={IngenTing} />
            <Route path="/sortImage" component={SortImage} />
          </Switch>
        </div>
      </SimpleReactLightbox>
    </ImageProvider>
  );
}

export default App;
