import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import { Button, Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from "./firebase.js";
import UserLogin from "./UserLogin";
import GlobalState from "./GlobalState";

import ImageUpload from "./ImageUpload";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 2, 3),
  },
}));

function Header() {
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (!nav) {
      return;
    }
    const overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = overflow;
    };
  }, [nav]);

  const handleNav = () => {
    setNav((prevState) => !prevState);
  };

  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (isSubscribed && ref.current && !ref.current.contains(event.target)) {
        setIsSubscribed(false);
      }
    };
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [isSubscribed]);

  const handleLink = () => {
    setNav(false);
    setIsSubscribed(false);
  };

  const handleChange = (event) => {
    setIsSubscribed(event.target.checked);
    // 👇️ this is the checkbox itself
    // console.log(event.target, "checkbox");

    // 👇️ this is the checked value of the field
    // console.log(event.target.checked, "field");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className="top-nav">
      <input
        id="menu-toggle"
        type="checkbox"
        onChange={handleChange}
        checked={isSubscribed}
      />
      <label
        className="menu-button-container"
        htmlFor="menu-toggle"
        onClick={handleNav}
        ref={ref}
      >
        <div className="menu-button"></div>
      </label>

      <Link to="/">
        <h1 className="header__text">
          <HomeRoundedIcon className="header__icon" fontSize="large" />
          KIILERICH
        </h1>
      </Link>

      <ul className="menu" onClick={handleLink}>
        <li>
          <div className="header__about">
            <Link className="header__link" to="About">
              <h3 className="header__text">
                {" "}
                <NotListedLocationIcon
                  className="header__icon"
                  fontSize="large"
                />{" "}
                KONTAKT INFO
              </h3>
            </Link>
          </div>
        </li>
        {/* <li>
          <div className="header__about">
            <Link className="header__link" to="About">
              <h3 className="header__text">
                {" "}
                <NotListedLocationIcon
                  className="header__icon"
                  fontSize="large"
                />{" "}
                UDSTILLINGER
              </h3>
            </Link>
          </div>
        </li> */}
        <li>
          <GlobalState.Provider value={[user, setUser]}>
            <UserLogin />
            {user ? (
              <div className="header__loggedIn">
                <button type="button" onClick={handleOpen}>
                  Upload
                </button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <h2 id="transition-modal-title">Gallery upload</h2>
                      <div id="transition-modal-description">
                        <ImageUpload />
                      </div>
                    </div>
                  </Fade>
                </Modal>
                <Button onClick={() => auth.signOut()}>Logout</Button>
              </div>
            ) : null}
          </GlobalState.Provider>
        </li>
      </ul>
    </section>
  );
}

export default Header;
