import React from "react";

import AndreTing from "./gallery/AndreTing";
import "./HomePage.scss";

function HomePage({ user }) {
  return (
    <div className="HomePage">
      <AndreTing />
    </div>
  );
}

export default HomePage;
