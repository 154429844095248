import firebase from "firebase";

// const firebaseApp = firebase.initializeApp({

//  });

// const app = firebase.initializeApp({
//   REACT_APP_API_KEY=
//   REACT_APP_AUTHDOMAIN=
//   REACT_APP_PROJECT_ID=
//   REACT_APP_STORAGE_BUCKET=
//   REACT_APP_MESSAGING_SENDER_ID=
//   REACT_APP_APP_ID=
//   REACT_APP_MEASUREMENT_ID=
// });

// const dev = {
//   firebase: {
//     apiKey: "AIzaSyBT4m7phy2Bj21mXpoxgq6KbqCRhQzFj5g",
//     authDomain: "kiilerich-dev.firebaseapp.com",
//     projectId: "kiilerich-dev",
//     storageBucket: "kiilerich-dev.appspot.com",
//     messagingSenderId: "222139833271",
//     appId: "1:222139833271:web:bd3243fffe47f59a82e632",
//     measurementId: "G-1YNRS3P773",
//   },
// };

// const prod = {
//   firebase: {
//     apiKey: "AIzaSyDvieN8LgmBpvzcuc5nsFFBRHtWb8FJh2E",
//     authDomain: "kiilerich-21616.firebaseapp.com",
//     databaseURL: "https://kiilerich-21616.firebaseio.com",
//     projectId: "kiilerich-21616",
//     storageBucket: "kiilerich-21616.appspot.com",
//     messagingSenderId: "556779433487",
//     appId: "1:556779433487:web:4238c933a3ee60a91d3d7a",
//     measurementId: "G-91VS25SE0X",
//   },
// };

// const config = process.env.REACT_APP_STAGE ? prod : dev;

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseUrl: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
