import React, { useState, useEffect, createContext } from "react";
import { db } from "./firebase.js";

export const ImageConext = createContext();

export const ImageProvider = (props) => {
  const [andreTing, setAndreTing] = useState([]);

  useEffect(() => {
    const collection = db
      .collection("andreTing")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        setAndreTing(
          snapshot.docs
            .map((doc) => ({
              id: doc.id,
              image: doc.data(),
            }))
            .sort((a, b) => {
              if (a.image.solgt && !b.image.solgt) {
                return 1;
              } else if (!a.image.solgt && b.image.solgt) {
                return -1;
              } else {
                return (
                  new Date(b.image.timestamp) - new Date(a.image.timestamp)
                );
              }
            })
        );
      });
    return collection;
  }, []);

  return (
    <ImageConext.Provider value={[andreTing, setAndreTing]}>
      {props.children}
    </ImageConext.Provider>
  );
};
