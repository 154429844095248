import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "./Login.scss";
import { auth } from "./firebase.js";
import { Button, Input } from "@material-ui/core";
import GlobalState from "./GlobalState";
import UserLogin from "./UserLogin";

function Login() {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  // const [username] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (event) => {
    event.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => alert(error.message));
    setEmail("");
    setPassword("");
  };

  return (
    <div className="Login">
      <form className="login__signup">
        <Input
          placeholder="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" onClick={signIn}>
          Sign In
        </Button>
      </form>
      <GlobalState.Provider value={[user, setUser]}>
        <UserLogin />
        {user ? (
          <div>
            <Redirect to="" />
          </div>
        ) : (
          <div className="app_loginContainer">not logged in</div>
        )}
      </GlobalState.Provider>
    </div>
  );
}

export default Login;
