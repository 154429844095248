import React from 'react'
import './About.scss'
import instafinal1 from './gallery/Instafinal-01.webp'
import instafinal2 from './gallery/Instafinal-02.webp'
import hvidovre from './gallery/hvidovre.webp'
import Limfjorden from './gallery/Limfjorden.webp'
import CK from './gallery/CK.webp'
import VB from './gallery/VB.webp'
import Tørring from './gallery/tørring-uldum.webp'
import Space from './gallery/space.webp'
import cphArt from './gallery/cph-art.webp'
import DKC2022 from './gallery/DKC2022.webp'
import nordjylland from './gallery/nordjylland.webp'
import sydjysk from './gallery/sydjysk.webp'
import soloStory from './gallery/solo-story.webp'
function About() {
  return (
    <div className='about'>
      <div className='about__lars'>
        <strong> Lars Kiilerich </strong> <br /> <br /> Født i København, august
        1958 <br />
        Uddannet bygningsmaler, Københavns Tekniske Skole 1981.
        <br /> <br />
        <div>
          kontakt: <br /> Mail:{' '}
          <a href='mailto: Lars.tv@mail.dk'> Lars.tv@mail.dk </a>
          <br /> Mobil: (+ 45) 30100317
          <br /> <br />
        </div>
      </div>

      <div className='about__portfolie'>
        <strong>Udstillinger</strong> <br />
        <div className='about__image'>
          <img src={soloStory} alt='Solo Story' />
          <img src={sydjysk} alt='Sydjysk kunstudstilling' />
          <img src={nordjylland} alt='Cencurede udstillinger Nordjylland' />
          <img src={DKC2022} alt='cencurerede deltagerliste' />
          <img src={Tørring} alt='Tørring-Uldum kunstforening' />
          <img src={cphArt} alt='CPH Art space filosoffen Odense' />
          <img src={Space} alt='CPH Art space filosoffen Odense' />
          <img src={Limfjorden} alt='Kunst foreningen Limfjorden' />
          <img src={instafinal1} alt='London paint club' />
          <img src={instafinal2} alt='London paint club' />
          <img src={hvidovre} alt='Hvidovre censured udstilling' />
          <img src={VB} alt='Værker bag glas' />
          <img src={CK} alt='Cencureret kunst udstilling' />
        </div>
      </div>
    </div>
  )
}

export default About
