import React, { useState } from "react";
import "./ArtCard.scss";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import GlobalState from "./GlobalState";
import UserLogin from "./UserLogin";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function ArtCard({
  title,
  imageUrl,
  id,
  folder,
  alt,
  solgt,
  dimension,
  description,
}) {
  const [user, setUser] = useState(null);

  return (
    <div className="artCard">
      <h3 className="artCard__title">{title}</h3>

      <h5> {solgt ? "SOLGT" : null}</h5>

      <LazyLoadImage
        effect="blur"
        className="artCard__image"
        src={imageUrl}
        alt={alt}
        key={id}
      />
      <h4>{description}</h4>
      {dimension ? <h4>MÅL: {dimension}</h4> : null}

      <GlobalState.Provider value={[user, setUser]}>
        <UserLogin />
        {user ? (
          <div>
            <DeleteButton id={id} folder={folder} />
            <EditButton
              key={id}
              id={id}
              folder={folder}
              title={title}
              alt={alt}
              solgt={solgt}
              dimension={dimension}
              description={description}
            />
          </div>
        ) : null}
      </GlobalState.Provider>
    </div>
  );
}

export default ArtCard;
