import React, { useEffect, useContext } from "react";
import GlobalState from "./GlobalState";
import { auth } from "./firebase.js";

function UserLogin() {
  const [user, setUser] = useContext(GlobalState);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // user has logged in.
        // console.log(authUser);
        setUser(authUser);
      } else {
        // user has logged out..
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user, setUser]);

  return <div></div>;
}

export default UserLogin;
