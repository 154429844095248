import React, { useState } from "react";
import "./ImageUpload.scss";
import { Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Resizer from "react-image-file-resizer";
// import IconButton from "@material-ui/core/IconButton";
// import PhotoCamera from "@material-ui/icons/PhotoCamera";

import { db, storage } from "./firebase.js";
import firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function ImageUpload() {
  const [title, setTitle] = useState("");
  const [alt, setAlt] = useState("");
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState(null);
  const [imageFolder, setImageFolder] = useState("andreTing");
  const [solgt, setSolgt] = useState(false);
  const [dimension, setDimension] = useState("");
  const [description, setDescription] = useState("");
  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const classes = useStyles();

  const handleUpload = (event) => {
    event.preventDefault();
    if (image) {
      try {
        Resizer.imageFileResizer(
          image,
          1280,
          1080,
          "WEBP",
          10,
          0,
          (uri) => {
            const uploadTask = storage
              .ref(`${imageFolder}/${image.name}`)
              .put(uri);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                //progress function..
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
              },
              (error) => {
                // Error function ...
                alert("Fejl ved upload", error.message);
              },
              () => {
                // Complete function...
                console.log("complete", uri);
                storage
                  .ref(`${imageFolder}`)
                  .child(image.name)
                  .getDownloadURL()
                  .then((url) => {
                    db.collection(`${imageFolder}`).add({
                      timestamp:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      title: title,
                      alt: alt,
                      imageUrl: url,
                      solgt: solgt,
                      dimension: dimension,
                      description: description,
                    });
                    setProgress(0);
                    setImage(null);
                    setTitle("");
                    setAlt("");
                    setImageFolder("");
                    setDescription("");
                  });
              }
            );
          },
          "file"
        );
      } catch (error) {
        console.error("Upload error", error);
      }
    }
    console.log("done", image);
  };

  return (
    <div className={classes.root}>
      <div className="imageupload">
        <progress
          className="imageupload__progress"
          value={progress}
          max="100"
        />
        <input
          type="text"
          placeholder="Enter a title..."
          onChange={(event) => setTitle(event.target.value)}
          value={title}
        />
        <input
          type="text"
          placeholder="Enter alt text..."
          onChange={(event) => setAlt(event.target.value)}
          value={alt}
        />
        <input
          type="text"
          placeholder="Mål"
          onChange={(event) => setDimension(event.target.value)}
          value={dimension}
        />
        <input
          type="text"
          placeholder="Beskrivelse"
          onChange={(event) => setDescription(event.target.value)}
          value={description}
        />
        <label htmlFor="solgt">Solgt</label>
        <select onChange={(event) => setSolgt(event.target.value)}>
          <option defaultValue={false}>Nej</option>
          <option value={true}>Yes</option>
        </select>

        <form className="form" onSubmit={handleUpload}>
          <label htmlFor="album">Vælg Album </label>
          <select onChange={(event) => setImageFolder(event.target.value)}>
            {/* <option defaultValue="Vælg...">Vælg...</option> */}

            <option value="andreTing">andreTing</option>
            <option value="ingenTing">ingenTing</option>
            <br />

            <option value="new">new</option>
          </select>
          <input
            type="file"
            accept=".jpg, .png, .jpeg, .gif, .heif, .heic, .bmp, .tif, .tiff|image/*"
            onChange={handleChange}
          />
          <Button
            color="primary"
            variant="contained"
            disabled={
              !title ||
              !image ||
              !alt ||
              imageFolder === "Vælg..." ||
              imageFolder === ""
            }
            className="imageupload__button"
            type="submit"
          >
            Upload
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ImageUpload;
