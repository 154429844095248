import React, { useState, useContext, useRef } from "react";
import { Button, makeStyles, Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { db } from "./firebase.js";
import EditIcon from "@material-ui/icons/Edit";
import firebase from "firebase";
import { ImageConext } from "./imageContext";

import Switch from "@mui/material/Switch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 2, 3),
    display: "flex",
    flexDirection: "column",
  },
}));

function EditButton({ id, folder, title, alt, solgt, dimension, description }) {
  const ref = useRef();
  // eslint-disable-next-line
  const [andreTing] = useContext(ImageConext);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(title);
  const [altInput, setAltInput] = useState(alt);
  const [solgtEdit, setSolgtEdit] = useState(null);
  const [dimensionEdit, setDimensionEdit] = useState(dimension);
  const [descriptionEdit, setDescriptionEdit] = useState(description);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSolgtEdit(event.target.checked);
  };

  const updateImage = () => {
    //updates
    db.collection(folder).doc(id).set(
      {
        title: input,
        alt: altInput,
        description: descriptionEdit,
        dimension: dimensionEdit,
        solgt: solgtEdit,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
    setOpen(false);
  };

  return (
    <>
      <Modal
        ref={ref}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h1>Edit</h1>
          Titel:
          <input
            placeholder={input}
            value={input ?? title ?? ""}
            onChange={(event) => setInput(event.target.value)}
          />
          Description:
          <input
            placeholder={description}
            value={descriptionEdit ?? description ?? ""}
            onChange={(event) => setDescriptionEdit(event.target.value)}
          />
          Alt:
          <input
            placeholder={altInput}
            value={altInput ?? alt ?? ""}
            onChange={(event) => setAltInput(event.target.value)}
          ></input>
          Mål:
          <input
            placeholder={dimensionEdit}
            onChange={(event) => setDimensionEdit(event.target.value)}
            value={dimensionEdit ?? dimension ?? ""}
          />
          <label htmlFor="solgt">Solgt</label>
          <Switch
            checked={solgtEdit ?? solgt ?? ""}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Button onClick={updateImage}>Update</Button>
        </div>
      </Modal>
      <div>
        <button onClick={(e) => setOpen(true)}>
          edit me <EditIcon />
        </button>
      </div>
    </>
  );
}
export default EditButton;
